import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Vendedor } from './vendedor';
import { SERVER_API_URL } from './app.constants';
import { Observable } from 'rxjs';
import { User } from './user';
import { map } from 'rxjs/operators';
import { Produto } from './produto';

export type EntityProdutoResponseType = HttpResponse<Produto[]>;


@Injectable()
export class AppComponentService {


  private resourceUrl =  SERVER_API_URL;

  private user = {
    username: 'admin',
    password: 'admin',
    rememberMe: true
  };

  private httpHeaders = new HttpHeaders({
    'Content-Type' : 'application/json',
    'Cache-Control': 'no-cache'
  });

  options: any;

  constructor( private http: HttpClient) {
    this.options = {
      headers: this.httpHeaders
    };
  }

  getDataBase(idToken: any, idVendedor: any): Observable<Produto[]> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Cache-Control', 'no-cache')
      .set('Authorization', 'Bearer ' + idToken);
    const options = {
      headers: httpHeaders
    };

    return this.http.get<Produto[]>(this.resourceUrl + '/api/produtosAtivos/' + idVendedor + '?page=0&size=1000' , options);
  }


  getIdToken(): Observable<any> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');
    const options = {
      headers: httpHeaders
    };
    return this.http.post(this.resourceUrl + '/api/authenticate', this.user, options);
  }

  private convertArrayResponse(res: HttpResponse<Vendedor[]>): HttpResponse<Vendedor[]> {
    const jsonResponse: Vendedor[] = res.body;
    const body: Vendedor[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < jsonResponse.length; i++) {
        body.push(this.convertItemFromServer(jsonResponse[i]));
    }
    return res.clone({body});
}
  /**
   * Convert a returned JSON object to SysAlarm.
   */
  private convertItemFromServer(fornecedor: Vendedor): Vendedor {
    const copy: Vendedor = Object.assign({}, fornecedor);
    return copy;
  }
}
