import { Component, OnInit, Inject } from '@angular/core';
import { MessageService} from 'primeng/components/common/messageservice';
import { ActivatedRoute } from '@angular/router';
import { AppComponentService } from './app.component.service';
import { SelectItem } from 'primeng/api';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Produto } from './produto';
import { Item } from './item';
import { Carrinho } from './carrinho';
import { Vendedor } from './vendedor';
import { isNumeric } from 'rxjs/util/isNumeric';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'PedeZapUI';

  produtos: Produto[];
  selectedProd: Produto;
  itemSelected: Item;
  displayDialog: boolean;
  displayCarrinho = false;
  carrinho: Carrinho = {itens: [], total: 0 };
  idVendedor: string;
  idToke: string;
  nomeVendedor: string;
  whatsappNumber: string;
  taxaEntrega = 0;


  sortKey: string;
  sortField: string;
  sortOrder: number;

  sortOptions: SelectItem[];

  textoWhatsApp: any = '';

  constructor(
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private service: AppComponentService
  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.idVendedor = params.vendedor;
    });
  }
  ngOnInit() {

    this.messageService.add({severity: 'error', summary: 'Quantidade!', detail: 'O Campo quantidade tem que ser numerico maio que ' });

    this.service.getIdToken().subscribe(
      resp => {
        console.log(resp.id_token);
        this.idToke = resp.id_token;
        this.service.getDataBase(this.idToke, this.idVendedor).subscribe(
          (res: Produto[]) => {
            console.log();
            this.produtos = res;
            this.nomeVendedor = this.produtos[0].vendedor.nomeFantasia;
            const phoneNumber = this.produtos[0].vendedor.whatsApp;
            this.carrinho.total = this.produtos[0].vendedor.taxaEntrega;
            this.taxaEntrega = this.produtos[0].vendedor.taxaEntrega;
            this.whatsappNumber = '55';
            this.whatsappNumber += phoneNumber.substring(1, 3);
            this.whatsappNumber += phoneNumber.substring(4, 9);
            this.whatsappNumber += phoneNumber.substring(10, 14);
          },
          (res: HttpErrorResponse) => console.log(res.message)
        );
      });

    this.sortOptions = [
      {label: 'Menor preço', value: 'preco'},
      {label: 'Maior preço', value: '!preco'},
      {label: 'Descrição', value: 'nome'}
    ];

    this.displayDialog = false;
    this.itemSelected = {
      produto: {
        id: '', codigo: '', nome: '', preco: 0, foto: '', fotoContentType: '', detalhe: '', flagAtivo: false }, quantidade: 0};
  }

  selectProduto(prod: Produto) {
    this.selectedProd = prod;
    this.itemSelected.produto = prod;
    this.itemSelected.quantidade = 1;
    this.displayDialog = true;
  }

  onSortChange(event) {
    const value = event.value;
    console.log(value);
    if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);

    } else {
        this.sortOrder = 1;
        this.sortField = value;
    }
  }

  addCarrinho() {

    if ((this.itemSelected.quantidade.length === 0 ) ||
        (!isNumeric(this.itemSelected.quantidade)) ||
        this.itemSelected.quantidade < 1) {
      this.messageService.add({severity: 'error', summary: 'Quantidade!', detail: 'O Campo quantidade tem que ser numerico maio que ' });
    } else {
      this.displayDialog = false;
      const item = {
        produto: {
          id: '', codigo: '', nome: '', preco: 0, foto: '', fotoContentType: '', detalhe: '', flagAtivo: false }, quantidade: 0};
      item.produto.id = this.itemSelected.produto.id;
      item.produto.preco = this.itemSelected.produto.preco;
      item.produto.codigo = this.itemSelected.produto.codigo;
      item.produto.nome = this.itemSelected.produto.nome;
      item.quantidade = +this.itemSelected.quantidade;
      this.carrinho.itens.push(item);
      this.carrinho.total = this.carrinho.total + (item.produto.preco * item.quantidade);
      this.messageService.add({severity: 'info', summary: 'Itens inserido no carrinho', detail: item.produto.nome });
    }

  }

  removeCarrinho(item: Item) {

    this.carrinho.itens.forEach((prod, index) => {
      if ( prod === item) {
        this.carrinho.itens.splice(index, 1);
        this.carrinho.total -= item.produto.preco * item.quantidade;
      }
    });
  }

  showCarrinho() {
    if (this.carrinho.itens.length === 0 ) {
      this.messageService.add({severity: 'error', summary: 'Carrinho vazio!', detail: 'Adicione produtos ao carrinho' });
    } else {
      this.displayCarrinho = true;
    }

  }

  fecharPedido() {
    this.displayCarrinho = false;

    this.textoWhatsApp = '';
    this.textoWhatsApp += '\n\n*Pedido ' + this.nomeVendedor + '* \n';
    this.textoWhatsApp += '*______________________________________________*' + '\n';

    for (const item of this.carrinho.itens) {
      this.textoWhatsApp += '\n*' + item.quantidade + '* x \t';
      this.textoWhatsApp += item.produto.codigo + ' - \t';
      this.textoWhatsApp += item.produto.nome + '\t' ;
      this.textoWhatsApp += '*' + (item.produto.preco  * item.quantidade).toFixed(2) + '*\n';
    }

    this.textoWhatsApp += '\n';
    this.textoWhatsApp += '*______________________________________________*' + '\n';
    this.textoWhatsApp += '*Total do pedido = R$ ' + this.carrinho.total.toFixed(2) + '* \n';

    this.document.location.href = 'https://api.whatsapp.com/send?phone=' + this.whatsappNumber + '&text=' + encodeURI(this.textoWhatsApp);
  }
}
