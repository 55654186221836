import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { DataViewModule} from 'primeng/dataview';
import { DropdownModule} from 'primeng/dropdown';
import { FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PanelModule} from 'primeng/panel';
import { ButtonModule} from 'primeng/button';
import { DialogModule} from 'primeng/dialog';
import { SpinnerModule} from 'primeng/spinner';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponentService } from './app.component.service';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    DataViewModule,
    DropdownModule,
    FormsModule,
    BrowserAnimationsModule,
    PanelModule,
    ButtonModule,
    DialogModule,
    SpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    TableModule,
    ScrollPanelModule,
    ProgressSpinnerModule
  ],
  providers: [
    AppComponentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
